import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Metrics } from '../../Themes';

type Props = {
  classes: Object,
};

const LOADER_SIZE = 40;

const styles = () => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginTop: -Metrics.navBarHeight - LOADER_SIZE / 2,
  },
});

function FullScreenLoader(props: Props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} size={LOADER_SIZE} />
    </div>
  );
}
export default withStyles(styles)(FullScreenLoader);
